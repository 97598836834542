import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {TierInterface} from "../../../interfaces/billing/tier.Interface";
import {ModalHeaderComponent} from '../../../layout/modal-header/modal-header.component';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {NgIf, UpperCasePipe} from '@angular/common';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {ModalFooterComponent} from '../../../layout/modal-footer/modal-footer.component';

@Component({
    selector: 'app-change-tier-confirmation',
    templateUrl: './change-tier-confirmation.component.html',
    styleUrls: ['./change-tier-confirmation.component.scss'],
    standalone: true,
    imports: [ModalHeaderComponent, CdkScrollable, MatDialogContent, NgIf, MatFormField, MatLabel, MatInput, FormsModule, MatDialogActions, ModalFooterComponent, UpperCasePipe]
})
export class ChangeTierConfirmationComponent implements OnInit {
  input = ''
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      tier: TierInterface,
      mode: 'upgrade' | 'downgrade',
      extractor: boolean,
      currentlyUsingExtractor: boolean,
    },
    public dialogRef: MatDialogRef<ChangeTierConfirmationComponent>
  ) {}

  ngOnInit(): void {
  }

  confirm() {
    if (this.input.toUpperCase() === this.data.tier.name.toUpperCase()) {
      this.dialogRef.close(true)
    }
  }

}

import {Injectable} from '@angular/core';
import {WorkspaceInterface} from "../interfaces/workspace.interface";
import {BehaviorSubject, first, firstValueFrom} from "rxjs";
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";
import {WorkflowRunInterface} from "../interfaces/workflow/workflow-run.interface";
import {KeysInterface} from "../interfaces/keys.interface";
import {RolesType} from "../interfaces/user/roles.type";
import {addDays, addMonths, endOfDay, format, startOfDay, startOfToday, subDays} from "date-fns";
import {HelperService} from "./helper.service";
import {TourStepsType} from "../interfaces/tour-steps.type";
import {
  average,
  collection, count,
  doc,
  Firestore, getAggregateFromServer,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter, sum,
  updateDoc,
  where
} from "@angular/fire/firestore";
import {PaddleInterface} from "../interfaces/paddle/paddle.interface";
import {TierInterface} from "../interfaces/billing/tier.Interface";
import {WorkspaceDailyInterface} from "../interfaces/workspace/workspace-daily.interface";
import {AdditionalCostsInterface} from "../interfaces/workspace/additional-costs.interface";
import {formatInTimeZone} from "date-fns-tz";
import {DayRange} from "../interfaces/workspace/date-range.interface";
import {DailyStatInterface} from "../interfaces/workspace/daily-stat.interface";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  private workspaces = new BehaviorSubject<WorkspaceInterface[]>([]);
  workspaces$ = this.workspaces.asObservable();
  public workspaceId: string // Set in navigation component
  private workspace = new BehaviorSubject<WorkspaceInterface | undefined>(undefined);
  workspace$ = this.workspace.asObservable();
  private role = new BehaviorSubject<RolesType>('viewer');
  role$ = this.role.asObservable();
  public roleSet = false
  constructor(
    private authSvc: AuthService,
    private router: Router,
    private helperSvc: HelperService,
    private firestore: Firestore,
    private http: HttpClient
  ) {
    this.authSvc.user$.subscribe(async (user) => {
      if (user) {
        const workspaces = await this.getAllWorkspaces(user.uid)
        if (workspaces.length === 0) {
          await this.router.navigate(['/home'])
          return
        }
        // const params = await firstValueFrom(activatedRoute.paramMap)
        let id = localStorage.getItem('workspaceId')
        if (!id || (id && !workspaces.find(x => x.id === id))) {
          id = workspaces[0].id
          localStorage.setItem('workspaceId', id)
        }
        if (!this.roleSet) {
          await this.getRole(null, '', id)
        }
      }
    })
  }

  async getAllWorkspaces(uid: string) {
    const q = query(
      collection(this.firestore, 'workspaces'),
      where('users', 'array-contains-any', [uid])
    )
    const snapshot = await getDocs(q)
    const workspaces = snapshot.docs.map((doc) => {
      const data = doc.data() as WorkspaceInterface;
      data.id = doc.id;
      return data;
    });
    this.workspaces.next(workspaces);
    return workspaces;
  }

  async getWorkspace(workspaceId: string, setLocalStorage = false): Promise<WorkspaceInterface | null> {
    const ref = doc(this.firestore, 'workspaces', workspaceId)
    const snap = await getDoc(ref)
    if (snap.exists()) {
      const data = snap.data() as WorkspaceInterface
      data.id = snap.id
      if (setLocalStorage) {
        localStorage.setItem('workspaceId', snap.id)
        localStorage.setItem('lastWorkspaceUsed', snap.id)
      }
      return data
    } else {
      return null
    }
  }

  async getKeys(workspaceId: string): Promise<KeysInterface | null> {
    const ref = doc(this.firestore, 'keys', workspaceId)
    const snap = await getDoc(ref)
    if (snap.exists()) {
      return snap.data() as KeysInterface
    } else {
      return null
    }
  }

  async changeAuthToken(workspaceId: string) {
    const newToken = this.helperSvc.generateId(25)
    const ref = doc(this.firestore, 'keys', workspaceId)
    await updateDoc(ref, {
      trigger: newToken
    })
    return newToken
  }

  async getRole(workspace: WorkspaceInterface | null, uid: string, workspaceId: string = ''): Promise<RolesType> {
    if (!workspace) {
      workspace = await this.getWorkspace(workspaceId) as WorkspaceInterface
    }
    this.workspace.next(workspace)
    if (!uid) {
      const user = await firstValueFrom(this.authSvc.user$)
      uid = user?.uid ?? ''
    }
    if (!uid) {
      console.error('Could not get role for workspace', workspace.id)
    }
    if (workspace.admins.indexOf(uid) !== -1) {
      this.role.next('admin')
      return 'admin'
    } else if (workspace.editors.indexOf(uid) !== -1) {
      this.role.next('editor')
      return 'editor'
    } else {
      this.role.next('viewer')
      return 'viewer'
    }
  }

  getWorkspaceIdFromUrl(url: string = ''): string {
    if (!url) {
      url = this.router.url
    }
    const split = url.split('/')
    return split[2] ?? ''
  }

  async createWorkspace(workspace: WorkspaceInterface, uid: string) {
    const freeTierRef = doc(this.firestore, 'tiers', '0')
    const snap = await getDoc(freeTierRef)
    const freeTier = snap.data() as TierInterface

    workspace.meta = {
      createdAt: new Date().getTime(),
      createdBy: uid,
      lastUpdate: -1,
      lastUpdateBy: ''
    }
    workspace.admins = [uid]
    workspace.users = [uid]
    workspace.editors = [uid]
    workspace.seats = freeTier?.seats ?? 2
    workspace.tier = 0
    workspace.successfulRuns = 0
    workspace.totalRuns = 0
    workspace.maxRuns = freeTier?.workflowRuns ?? 200
    workspace.historyTTL = freeTier?.historyDays ?? 30
    workspace.triggerAuth = false
    workspace.currentPeriodRuns = 0
    workspace.maxWorkflows = freeTier?.workflows ?? 2
    workspace.maxIntegrations = freeTier?.integrations ?? 2
    workspace.extraRunCost = 0.1
    workspace.runOverflow = false
    workspace.periodStart = new Date().getTime()
    workspace.periodEnd = addMonths(new Date(), 1).getTime()
    workspace.tour = {
      intro: false,
      trigger: false,
      integration: false,
      workflowCreation: false,
      workflowTrigger: false,
      workflowInput: false,
      workflowComputations: false,
      workflowDestinations: false,
      workflowRuns: false
    }
    workspace.createdAt = new Date().getTime()
    workspace.additionalCost = 0
    workspace.paddleCustomer = ''
    workspace.appSumoCoupons = 0
    workspace.credits = 0
    workspace.totalCreditsPurchased = 0
    workspace.maxTier = 0
    workspace.dailyStatsResetTime = formatInTimeZone(startOfToday(), "UTC", "kk")
    const ref = doc(this.firestore, 'workspaces', workspace.id)
    await setDoc(ref, workspace)
    this.workspaceId = workspace.id
    this.workspaces.next(await this.getAllWorkspaces(uid))
    await this.getRole(workspace, uid)
    await this.router.navigate(['/workspace', workspace.id, 'workflows'])
  }

  async updateWorkspace(workspace: WorkspaceInterface) {
    workspace.meta.lastUpdateBy = this.authSvc.uid
    workspace.meta.lastUpdate = new Date().getTime()
    const ref = doc(this.firestore, 'workspaces', workspace.id)
    await updateDoc(ref, {
      name: workspace.name,
      description: workspace.description,
      triggerAuth: workspace.triggerAuth,
      meta: workspace.meta,
      dailyStatsResetTime: workspace.dailyStatsResetTime,
    })
  }

  async updateWorkflowCount(workspaceId: string, workflowCount: number) {
    const ref = doc(this.firestore, 'workspaces', workspaceId)
    await updateDoc(ref, {
      workflowCount,
    })
  }

  async updateWorkspaceMembers(workspace: WorkspaceInterface) {
    workspace.meta.lastUpdateBy = this.authSvc.uid
    workspace.meta.lastUpdate = new Date().getTime()
    const ref = doc(this.firestore, 'workspaces', workspace.id)
    await updateDoc(ref, {
      admins: workspace.admins,
      editors: workspace.editors,
      users: workspace.users
    })
  }

  async getLatestRuns(workspaceId: string, limitDocs = 20) {
    const q = query(
      collection(this.firestore, 'workspaces', workspaceId, 'runs'),
      orderBy('start', 'desc'),
      limit(limitDocs)
    )
    const snap = await getDocs(q)
    return snap.docs.map((doc) => {
      const data = doc.data() as WorkflowRunInterface;
      data.id = doc.id;
      return data;
    });
  }

  async getWorkflowRun(workspaceId: string, runId: string) {
    const ref = doc(this.firestore, 'workspaces', workspaceId, 'runs', runId)
    const snap = await getDoc(ref)
    if (snap.exists()) {
      const data = snap.data() as WorkflowRunInterface
      data.id = snap.id
      return data
    } else {
      return null
    }
  }


  async changeWorkspaceMemberRole(uid: string, newRole: string, workspace: WorkspaceInterface) {
    switch (newRole) {
      case 'read':
        workspace.editors = this.helperSvc._deleteArrayElement(workspace.editors, uid)
        workspace.admins = this.helperSvc._deleteArrayElement(workspace.admins, uid)
        break;
      case 'edit':
        workspace.editors = this.helperSvc._addArrayElement(workspace.editors, uid)
        workspace.admins = this.helperSvc._deleteArrayElement(workspace.admins, uid)
        break;
      case 'admin':
        workspace.editors = this.helperSvc._addArrayElement(workspace.editors, uid)
        workspace.admins = this.helperSvc._addArrayElement(workspace.admins, uid)
        break;
    }
    await this.updateWorkspaceMembers(workspace)
  }

  async addWorkspaceMember(newUid: string, newRole: string, workspace: WorkspaceInterface) {
    workspace.users = this.helperSvc._addArrayElement(workspace.users, newUid)
    if (newRole === 'edit' || newRole === 'admin') {
      workspace.editors = this.helperSvc._addArrayElement(workspace.editors, newUid)
    }
    if (newRole === 'admin') {
      workspace.admins = this.helperSvc._addArrayElement(workspace.admins, newUid)
    }
    await this.updateWorkspaceMembers(workspace)
  }

  async deleteWorkspaceMember(uid: string, workspace: WorkspaceInterface) {
    workspace.users = this.helperSvc._deleteArrayElement(workspace.users, uid)
    workspace.editors = this.helperSvc._deleteArrayElement(workspace.editors, uid)
    workspace.admins = this.helperSvc._deleteArrayElement(workspace.admins, uid)
    await this.updateWorkspaceMembers(workspace)
  }

  // updateWorkspaceTourStatus(step: TourStepsType, status: boolean) {
  //   this.workspace$.pipe(first()).subscribe((workspace) => {
  //     if (!workspace) { return }
  //     workspace.tour[step] = status
  //     this.workspace.next(workspace)
  //   })
  // }

  // async getWorkflowCount(workspaceId: string) {
  //   const q = query(
  //     collection(this.firestore, 'workspaces', workspaceId, 'workflows'),
  //   )
  //   const snap = await getCountFromServer(q)
  //   return snap.data().count
  // }

  async getWorkspaceRunsCount(workspaceId: string, limitDate: number) {
    const q = query(
      collection(this.firestore, 'workspaces', workspaceId, 'runs'),
      where('start', '>', limitDate)
    )
    const snap = await getCountFromServer(q)
    return snap.data().count
  }

  async getWorkspaceQuotaUsage(workspaceId: string, periodStart: number) {
    try {
      const response = await firstValueFrom(this.http.get(`${environment.core}workspace/quota/${workspaceId}?period_start=${periodStart}`)) as number
      console.log('Quota response', response)
      return response
    } catch (e) {
      // Fallback to count documents
      return await this.getWorkspaceRunsCount(workspaceId, periodStart)
    }
  }

  async runsAggregation(workspaceId: string, days: number) {
    const range = (days: number) => {
      const ranges: DayRange[] = [];
      for (let i = 0; i < days; i++) {
        const day = subDays(new Date(), i);
        ranges.push({
          start: startOfDay(day).getTime(),
          end: endOfDay(day).getTime(),
        });
      }
      return ranges;
    }

    const requests = range(days).map(async (day) => {
      const runsQuery = query(
        collection(this.firestore, "workspaces", workspaceId, "runs"),
        where("start", ">", day.start),
        where("start", "<", day.end)
      );

      const errorsQuery = query(
        collection(this.firestore, "workspaces", workspaceId, "runs"),
        where("start", ">", day.start),
        where("start", "<", day.end),
        where("error", "==", true)
      );

      const [runsSnapshot, errorsSnapshot] = await Promise.all([
        getAggregateFromServer(runsQuery, {
          runs: count(),
          additionalCost: sum("additionalCost"),
        }),
        getAggregateFromServer(errorsQuery, {
          issues: count(),
        }),
      ]);

      return {
        day: day.start,
        runs: runsSnapshot.data().runs,
        additionalCost: parseFloat(runsSnapshot.data().additionalCost.toFixed(4)),
        issues: errorsSnapshot.data().issues,
        dayString: format(new Date(day.start), "MMM do"),
      };
    });

    // Wait for all requests to complete
    return await Promise.all(requests) as DailyStatInterface[]
  }


  async getWorkspaceSuccessfulRunsCount(workspaceId: string, lastNDays = 30) {
    const limitDate = addDays(new Date(), -lastNDays)
    const q = query(
      collection(this.firestore, 'workspaces', workspaceId, 'runs'),
      where('error', '==', false),
      where('start', '>', limitDate.getTime())
    )
    const snap = await getCountFromServer(q)
    return snap.data().count
  }

  async getPaddleData(workspaceId: string): Promise<PaddleInterface | null> {
    const ref = doc(this.firestore, 'paddle-data', workspaceId)
    const snap = await getDoc(ref)
    if (snap.exists()) {
      return snap.data() as PaddleInterface
    } else {
      return null
    }
  }

  // async getDailyStats(workspaceId: string, lastNDays: number) { // Deprecated
  //   const q = query(
  //     collection(this.firestore, 'workspaces', workspaceId, 'daily'),
  //     orderBy('createdAt', 'desc'),
  //     startAfter(startOfDay(new Date()).getTime()),
  //     limit(lastNDays + 1)
  //   )
  //   const snap = await getDocs(q)
  //   return snap.docs.map((doc) => {
  //     return doc.data() as WorkspaceDailyInterface;
  //   });
  // }

  async getAdditionalCost(workspaceId: string) {
    const ref = doc(this.firestore, 'additionalCosts', workspaceId)
    const snap = await getDoc(ref)
    if (snap.exists()) {
      return snap.data() as AdditionalCostsInterface
    } else {
      return null
    }
  }
}

export const environment = {
  firebase: {
    projectId: 'call-center-d08cb',
    appId: '1:1030804807834:web:8b02ec1205399fef1e3f23',
    storageBucket: 'call-center-d08cb.appspot.com',
    apiKey: 'AIzaSyAiykT3uqzE0hTJa2waVB0FwTTcJk84rOk',
    authDomain: 'call-center-d08cb.firebaseapp.com',
    messagingSenderId: '1030804807834',
  },
  production: false,
  fireServer: 'https://us-central1-call-center-d08cb.cloudfunctions.net/',
  // v2Url: 'https://core-dev.datamorf.io/v2/',
  core: 'http://localhost:6060/',
  extractor: 'http://localhost:3000/',
  paddleVendorId: 14265,
  integrations: {
    calendly: {
      clientId: 'tafzhfi59y38LP9Y3hb045npZkuJhx_5ZT_Sn597SW0',
      redirectUri: 'https://auth.calendly.com/oauth/authorize?client_id=tafzhfi59y38LP9Y3hb045npZkuJhx_5ZT_Sn597SW0&response_type=code&redirect_uri=http://localhost:4200/integrations/calendly'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

